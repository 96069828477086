'use client'

import { useEffect, useRef, useState } from 'react'
import dynamic from 'next/dynamic'
import { usePathname } from 'next/navigation'
import { VideoSchema } from '@/contentful/shared'
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { ArrowRightIcon, X } from 'lucide-react'
import WistiaPlayer, { WistiaConfig } from 'react-player/wistia'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Link } from '@/components/ui/link'
import { ContentfulImage } from '@/components/contentful-image'
import { pushToDataLayer } from '@/components/datalayer'
import {
  VideoCard,
  VideoCardContent,
  VideoCardDescription,
  VideoCardHeader,
  VideoCardHeaderText,
  VideoCardTitle,
} from '@/components/video-card'

import { gridVariants } from './grid'

const ReactPlayer = dynamic(() => import('react-player/wistia'), { ssr: false })

interface VideoGridWrapperProps {
  blocks: VideoSchema[]
  className?: string
}

interface VideoEventData {
  playedPercentage?: number
  [key: string]: string | number | undefined
}

const renderOptions: Options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const asset = node.data.target.fields
      return (
        <div className="not-prose relative -mb-4 flex h-32 max-w-full md:h-52 lg:mb-2">
          <ContentfulImage src={asset.file.url} alt={asset.description} fill />
        </div>
      )
    },
    [INLINES.HYPERLINK]: (node, children) => {
      const href = node?.data?.uri
      return <Link href={href}>{children}</Link>
    },
  },
}

export function VideoCardPaginatedGrid({
  blocks,
  className,
}: VideoGridWrapperProps) {
  const INITIAL_VIDEOS_LOADED = 6
  const [visibleCount, setVisibleCount] = useState(INITIAL_VIDEOS_LOADED)
  const [activeTranscript, setActiveTranscript] = useState<VideoSchema | null>(
    null
  )
  const [playedPercentage, setPlayedPercentage] = useState(0)
  const videoRefs = useRef<(HTMLElement | null)[]>([])
  const playerRefs = useRef<(WistiaPlayer | null)[]>([])

  const hasMoreItems = blocks.length > visibleCount
  const config: WistiaConfig = { options: { popover: true } }

  const path = usePathname()

  const handleVideoEvent = (
    eventType: string,
    videoTitle: string,
    extraData: VideoEventData = {}
  ) => {
    pushToDataLayer({
      event: eventType,
      path: path,
      videoTitle: videoTitle,
      source: blocks[0]?.fields.partner,
      ...extraData,
    })
  }

  const handleProgress = (progress: { played: number }) => {
    setPlayedPercentage(Math.round(progress.played * 100))
  }

  useEffect(() => {
    if (visibleCount > INITIAL_VIDEOS_LOADED) {
      const newCardIndex = visibleCount - INITIAL_VIDEOS_LOADED
      const newCard = videoRefs.current[newCardIndex]
      if (newCard) {
        newCard.scrollIntoView({ behavior: 'smooth' })
        newCard.focus()
      }
    }
  }, [visibleCount])

  return (
    <div className="space-y-7 lg:space-y-9">
      <div className={cn(gridVariants({ layout: 'benefit' }), className)}>
        {blocks.slice(0, visibleCount).map((block, index) => (
          <VideoCard
            key={block.fields.internalName}
            ref={(el) => (videoRefs.current[index] = el)}
          >
            {block.fields.wistiaVideoId && (
              <div className="relative aspect-video w-full [&>div>div]:!w-full">
                <ReactPlayer
                  ref={(el) => (playerRefs.current[index] = el)}
                  width="100%"
                  height="100%"
                  url={`https://support.wistia.com/medias/${block.fields.wistiaVideoId}`}
                  data-testid="wistia-video"
                  controls={true}
                  playing={false}
                  config={config}
                  onStart={() =>
                    handleVideoEvent('videoStarted', block.fields.title)
                  }
                  onPause={() =>
                    handleVideoEvent('videoPaused', block.fields.title, {
                      playedPercentage,
                    })
                  }
                  onEnded={() =>
                    handleVideoEvent('videoEnded', block.fields.title, {
                      playedPercentage,
                    })
                  }
                  onProgress={handleProgress}
                />
              </div>
            )}
            <VideoCardContent>
              <VideoCardHeader>
                {block.fields.videoTranscript && (
                  <button
                    className="body-md-regular ml-auto w-fit cursor-pointer text-card-foreground underline"
                    onClick={() => setActiveTranscript(block)}
                  >
                    View transcripts
                  </button>
                )}
                <VideoCardHeaderText>
                  <VideoCardTitle>{block.fields.title}</VideoCardTitle>
                  {block.fields.content && (
                    <VideoCardDescription>
                      {documentToReactComponents(
                        block.fields.content,
                        renderOptions
                      )}
                    </VideoCardDescription>
                  )}
                </VideoCardHeaderText>
              </VideoCardHeader>
              {block.fields.wistiaVideoId && (
                <Button
                  variant="secondary-link"
                  className="w-fit"
                  onClick={() => {
                    const player = playerRefs.current[index]
                    if (player) {
                      const internalPlayer = player.getInternalPlayer()
                      if (internalPlayer) {
                        player.seekTo(0)
                        player.getInternalPlayer().play()
                      }
                    }
                  }}
                >
                  {block.fields.cta || 'Watch video'}
                  <ArrowRightIcon
                    aria-hidden="true"
                    className="size-3.5 stroke-[3]"
                  />
                </Button>
              )}
            </VideoCardContent>
          </VideoCard>
        ))}
      </div>

      {activeTranscript && (
        <Dialog open onOpenChange={() => setActiveTranscript(null)}>
          <DialogContent className="flex max-h-[80vh] max-w-[350px] flex-col border-olive-400 shadow-none sm:max-w-[600px]">
            <DialogHeader>
              <DialogTitle className="!heading-lg-regular text-left">
                {activeTranscript.fields.title}
                <hr className="border-olive-400" />
              </DialogTitle>
            </DialogHeader>
            <div className="prose grow overflow-y-auto py-4 pr-4 prose-hr:border-olive-400">
              {documentToReactComponents(
                activeTranscript.fields.videoTranscript!
              )}
            </div>
            <DialogClose asChild>
              <Button
                className="absolute right-5 top-4 sm:right-5"
                variant={'primary-accordion-link'}
              >
                <X />
                <span className="sr-only">Close</span>
              </Button>
            </DialogClose>
          </DialogContent>
        </Dialog>
      )}

      {hasMoreItems && (
        <div className="flex justify-center">
          <Button
            variant="primary-outline"
            className="w-full"
            onClick={() =>
              setVisibleCount((prev) => prev + INITIAL_VIDEOS_LOADED)
            }
            data-testid="load-more-button"
          >
            Load more
          </Button>
        </div>
      )}
    </div>
  )
}
