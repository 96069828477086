'use client'

import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { ArrowRight } from 'lucide-react'

import { cn } from '@/lib/utils'
import { buttonVariants } from '@/components/ui/button'
import { Link } from '@/components/ui/link'

const videoCardVariants = cva(
  'relative flex size-full overflow-hidden rounded-xl bg-card-primary shadow-lg',
  {
    variants: {
      variant: {
        default: 'flex-col items-start gap-2 pb-6 lg:pb-8',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)

interface VideoCardProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof videoCardVariants> {}

const VideoCard = React.forwardRef<HTMLDivElement, VideoCardProps>(
  ({ className, variant, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(videoCardVariants({ variant, className }))}
      {...props}
    />
  )
)
VideoCard.displayName = 'VideoCard'

const VideoCardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'flex size-full flex-col items-start justify-between gap-7 px-4 xl:gap-8',
      className
    )}
    {...props}
  />
))
VideoCardContent.displayName = 'VideoCardContent'

const VideoCardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('flex size-full flex-col items-start gap-6', className)}
    {...props}
  />
))
VideoCardHeader.displayName = 'VideoCardHeader'

const VideoCardHeaderText = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('flex w-full flex-col items-start gap-3', className)}
    {...props}
  />
))
VideoCardHeaderText.displayName = 'VideoCardHeaderText'

const VideoCardTitle = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, children, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn(
      'heading-xs-bold text-pretty text-card-foreground',
      className
    )}
    {...props}
  >
    {children}
  </h3>
))
VideoCardTitle.displayName = 'VideoCardTitle'

const VideoCardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn('text-card-foreground/80 body-md-regular', className)}
    {...props}
  />
))
VideoCardDescription.displayName = 'VideoCardDescription'

const VideoCardLink = React.forwardRef<
  HTMLAnchorElement,
  React.ComponentPropsWithoutRef<typeof Link>
>(({ className, children, ...props }, ref) => (
  <Link
    ref={ref}
    className={cn(
      buttonVariants({ variant: 'secondary-link' }),
      'items-center justify-start',
      className
    )}
    {...props}
  >
    {children}
    <ArrowRight className="size-3.5" />
  </Link>
))
VideoCardLink.displayName = 'VideoCardLink'

export {
  VideoCard,
  VideoCardContent,
  VideoCardDescription,
  VideoCardHeader,
  VideoCardHeaderText,
  VideoCardLink,
  VideoCardTitle,
}
