import * as React from 'react'
import Link from 'next/link'
import { cva, type VariantProps } from 'class-variance-authority'
import { TriangleAlert, X } from 'lucide-react'

import { cn } from '@/lib/utils'

import { Button } from './button'

const alertVariants = cva(
  'flex w-full items-center px-5 py-2 text-white md:px-6',
  {
    variants: {
      variant: {
        primary: 'bg-primary',
        destructive: 'bg-destructive',
        warning: 'bg-dialog-warning-dark',
        confirmation: 'bg-dialog-confirmation-dark',
        information: 'bg-dialog-information-dark',
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  }
)

const Alert = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants>
>(({ className, variant, children, ...props }, ref) => (
  <div
    ref={ref}
    role="alert"
    className={cn(alertVariants({ variant }), className)}
    {...props}
  >
    <div className="flex w-full items-center gap-3">
      <TriangleAlert className="size-5 stroke-[3]" />
      {children}
    </div>
  </div>
))
Alert.displayName = 'Alert'

const AlertContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('flex items-center gap-2', className)}
    {...props}
  >
    {children}
  </div>
))
AlertContent.displayName = 'AlertContent'

const AlertDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn('body-md-regular', className)} {...props} />
))
AlertDescription.displayName = 'AlertDescription'

const AlertLinkText = React.forwardRef<
  HTMLAnchorElement,
  React.ComponentPropsWithRef<typeof Link>
>(({ className, ...props }, ref) => (
  <Link
    ref={ref}
    className={cn('body-md-bold-link p-1 underline', className)}
    {...props}
  />
))
AlertLinkText.displayName = 'AlertLinkText'

const AlertClose = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof Button>
>(({ className, ...props }, ref) => (
  <button ref={ref} className={cn('ml-auto text-white', className)} {...props}>
    <X className="stroke-[3]" />
    <span className="sr-only">Close</span>
  </button>
))
AlertClose.displayName = 'AlertClose'

export { Alert, AlertClose, AlertContent, AlertDescription, AlertLinkText }
