'use client'

import { useEffect, useState } from 'react'

import {
  Alert,
  AlertClose,
  AlertContent,
  AlertDescription,
  AlertLinkText,
} from '@/components/ui/alert'

interface DismissibleAlertProps {
  id: string
  description: string
  linkText: string
  href: string
  variant?: 'default' | 'destructive'
}

export function DismissibleAlert({
  id,
  linkText,
  description,
  href,
  variant = 'default',
}: DismissibleAlertProps) {
  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    const isDismissed = localStorage.getItem(`alert-dismissed-${id}`)
    if (isDismissed) {
      setIsVisible(false)
    }
  }, [id])

  if (!isVisible) return null

  const handleDismiss = () => {
    localStorage.setItem(`alert-dismissed-${id}`, 'true')
    setIsVisible(false)
  }

  return (
    <Alert variant={variant}>
      <AlertContent>
        <AlertDescription>{description}</AlertDescription>
        <AlertLinkText href={href}>{linkText}</AlertLinkText>
      </AlertContent>
      <AlertClose onClick={handleDismiss} />
    </Alert>
  )
}
