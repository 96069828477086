'use client'

import { ArticleSchema } from '@/contentful/shared'
import { cva, type VariantProps } from 'class-variance-authority'
import { ArrowRight } from 'lucide-react'

import { contentConfig } from '@/config/content'
import { cn, sortBySubcategory } from '@/lib/utils'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPagination,
} from '@/components/ui/carousel'
import { Link, linkVariants } from '@/components/ui/link'
import {
  ArticleCard,
  ArticleCardCategory,
  ArticleCardContent,
  ArticleCardHeader,
  ArticleCardHeaderText,
  ArticleCardImage,
  ArticleCardLink,
  ArticleCardTags,
  ArticleCardTitle,
} from '@/components/article-card'

const carouselVariants = cva(
  'relative -mx-[calc((100vw-100%)/2)] flex justify-center overflow-hidden',
  {
    variants: {
      backgroundColor: {
        olive: 'bg-accent',
        white: 'bg-white',
      },
    },
    defaultVariants: {
      backgroundColor: 'olive',
    },
  }
)

interface ArticleCarouselProps extends VariantProps<typeof carouselVariants> {
  articles: ArticleSchema[]
  title?: string
  description?: string
  category?: string
  link?: {
    fields: {
      href: string
    }
  }
}

export function ArticleCarousel({
  articles,
  title = 'You might also like',
  description = 'Explore more articles related to this topic.',
  category,
  link,
  backgroundColor = 'olive',
}: ArticleCarouselProps) {
  const renderActionButton = () => {
    if (category && !link) {
      return (
        <Button asChild data-testid="view-all-item">
          <Link
            href={contentConfig.categoryPath(category)}
            className="h-full w-min"
          >
            View All
            <ArrowRight className="size-4 stroke-[3]" />
          </Link>
        </Button>
      )
    }

    if (link) {
      return (
        <Button asChild data-testid="view-all-item">
          <Link href={link.fields.href} className="h-full w-min">
            Explore the blog
            <ArrowRight className="size-4 stroke-[3]" />
          </Link>
        </Button>
      )
    }

    return null
  }

  return (
    <div className={cn(carouselVariants({ backgroundColor }))}>
      <div className="w-full max-w-screen-2xl px-5 py-8 sm:px-8 sm:py-10 lg:p-11">
        <div className="flex max-w-7xl flex-col gap-7 lg:gap-10">
          <div className="flex flex-col justify-between gap-6 sm:flex-row">
            <div className="flex min-w-0 flex-col gap-3">
              <h1 className="heading-xl-regular">{title}</h1>
              <div className="support-styles-intro-text-regular">
                {description}
              </div>
            </div>
            {renderActionButton()}
          </div>

          <Carousel
            opts={{ align: 'start' }}
            aria-label={`Carousel with ${articles.length} items`}
          >
            <CarouselContent>
              {articles.map(
                ({
                  fields: { slug, asset, category, subCategory, headline },
                }) => (
                  <CarouselItem key={slug}>
                    <ArticleCard>
                      <ArticleCardImage
                        src={`https://${asset?.fields.file?.url}`}
                        alt={asset?.fields.description || ''}
                      />
                      <ArticleCardContent>
                        <ArticleCardHeader>
                          <ArticleCardHeaderText>
                            <ArticleCardCategory>
                              {category}
                            </ArticleCardCategory>
                            <ArticleCardTitle>{headline}</ArticleCardTitle>
                          </ArticleCardHeaderText>
                          <ArticleCardTags>
                            {sortBySubcategory(subCategory)?.map((subCat) => {
                              const [category, subcategory] = subCat.split(':')
                              return (
                                <Link
                                  href={contentConfig.subcategoryPath(
                                    category,
                                    subcategory
                                  )}
                                  className={cn(
                                    linkVariants({
                                      variant: 'subcategory-badge',
                                    })
                                  )}
                                  key={`${category}-${subCat}`}
                                >
                                  <Badge>{subcategory}</Badge>
                                </Link>
                              )
                            })}
                          </ArticleCardTags>
                        </ArticleCardHeader>
                        <ArticleCardLink href={contentConfig.articlePath(slug)}>
                          Read now
                        </ArticleCardLink>
                      </ArticleCardContent>
                    </ArticleCard>
                  </CarouselItem>
                )
              )}
            </CarouselContent>
            <CarouselPagination />
          </Carousel>
        </div>
      </div>
    </div>
  )
}
